<template>
  <v-app id="app">
    <v-app-bar color="primary" app elevation="2" id="navbar-wrapper">
      <v-container style="margin: 0 auto">
        <header style="color: white" id="navbar">
          <v-icon id="navbar-icon" color="secondary" large
            >mdi-bacteria-outline</v-icon>
          <h1 id="navbar-title">Microbiome Data Portal</h1>
          <div style="float: right;">
            <a href="https://microbiome.mit.edu/" target="_blank">
              <img id="navbar-logo" src="./assets/images/cmit-logo-white.png" width="140px" alt="cmitlogo" />
            </a>
          </div>
        </header>
      </v-container>
    </v-app-bar>
    <v-main app>
      <div style="background-color: rgb(255,255,255); width: 100%">
        <div id="banner">
          <div style="position: relative">
            <img src="./assets/images/cmit-banner.png" width="100%" alt="bubble image">
            <div  class="gradient-banner" style="width: 100%; height: 100%; position: absolute; top: 0"></div>
          </div>
          <div id="banner-text-wrapper">
            <h1>Discover the latest in microbiome research</h1>
          </div>
        </div>
      </div>
      <div class="b-container top-card-container">
        <div class="d-flex" style="gap: 30px">
          <div class="rounded pa-4">
            <v-card elevation="5" class="top-cards">
              <v-icon color="secondary">mdi-file-table-box-multiple</v-icon>
            <h3>Explore microbiome datasets across multiple diseases</h3>
          </v-card>
          </div>
          <div class="rounded pa-4" style="color: black">
            <v-card elevation="5" class="top-cards">
              <v-icon color="secondary">mdi-chart-bubble</v-icon>
            <h3>Visualize bacteria diversity across study sample groups</h3>
          </v-card>
          </div>
          <div class="rounded pa-4" style="color: black">
            <v-card elevation="5" class="top-cards">
              <v-icon color="secondary">mdi-compare</v-icon>
            <h3>Compare groups using real-time statistical analyses</h3>
          </v-card>
          </div>
        </div>
      </div>
      <div id="stepper-wrapper">
        <v-stepper
          class="mb-8"
          v-model="stepIndex"
          id="stepper-container"
        >
          <v-stepper-header>
            <v-stepper-step :complete="stepIndex > 1" step="1">
              Select a dataset
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="stepIndex > 2" step="2">
              Start analysis
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Explore results </v-stepper-step>
          </v-stepper-header>

          <!-- START OF THE STEPPER CONTENT -->
          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="flex-box">
                <div style="flex: 1; margin-top: 20px">
                  <h2 style="margin-bottom: 20px">Which disease are you interested in?</h2>
                  <p>
                    Our app combines <strong>{{ Object.values(datasetNames).length }} datasets</strong> from <a href="https://zenodo.org/record/1146764#.Y-FvGOzML0p" target="_blank">MicrobiomeHD</a> across
                    <strong>{{ diseaseCategory.length }} diseases</strong> to help answer important
                    questions about the bacteria in our gut.
                  </p>
                  <p>
                    Choose one of the disease areas to select a dataset.
                  </p>
                </div>
                <div style="flex: 2" class="margin-desktop-only">
                  <v-row
                    justify="center"
                    style="
                      margin-top: 20px;
                      margin-bottom: 20px;
                      max-width: 800px;
                      margin-left: auto;
                      margin-right: auto;
                    "
                  >
                    <v-expansion-panels v-model="openPanel">
                      <v-expansion-panel
                        v-for="(item, i) in diseaseCategory"
                        :key="i"
                        v-model="openPanel"
                      >
                        <v-expansion-panel-header
                          class="
                            blue-grey
                            lighten-4
                            black--text
                            text--lighten-5
                            rounded-0
                            disease-panel-headers
                          "
                        >
                          {{ item.name }}
                          <template v-slot:actions>
                            <v-icon color="primary" medium
                              >mdi-bacteria-outline</v-icon
                            >
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="blue-grey lighten-4">
                          <div
                            class="flex-cards mt-4"
                            style="margin-bottom: 30px"
                          >
                            <v-card
                              class="mx-auto blue-grey darken-2"
                              v-for="(auth,i) in item.authors"
                              :key="i"
                              style="width: 100%; max-width: 400px"
                            >
                              <v-card-text>
                                <div class="top-card-section">
                                  <span class="white--text study-title">
                                    {{auth.title }}
                                  </span>
                                  <v-icon
                                    class="db-icon"
                                    color="secondary"
                                    xLarge
                                    >mdi-database</v-icon>
                                </div>
                                <div>
                                <p class="blue-grey--text text--lighten-2 author-name">
                                  {{ auth.name }}
                                </p>
                                <div class="doi-link">
                                  <a :href="auth.doi" target="_blank">Full article</a>
                                  <v-icon style="display: inline-block" xSmall>mdi-open-in-new</v-icon>
                                </div>
                              </div>
                              </v-card-text>
                              <v-card-actions style="margin-top: -20px">
                                <v-btn
                                  text
                                  color="white"
                                  @click="
                                    revealCardNum = i;
                                    updateDatasetName(auth.name);
                                  "
                                >
                                  select
                                </v-btn>
                                <v-icon
                                  v-if="revealCardNum == i"
                                  color="secondary"
                                  >mdi-check-circle</v-icon
                                >
                                <v-btn
                                  v-if="revealCardNum == i"
                                  color="white"
                                  @click="
                                    stepIndex = 2;
                                    prefillPopSelection();
                                    $vuetify.goTo('#stepper-wrapper', {duration: 100})
                                  "
                                  class="right-align-btn"
                                >
                                  Continue
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </div>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2">
              <div class="blue-grey--text text--darken-4">
                <div style="margin-bottom: 20px">
                  <div>
                    <v-btn @click="stepIndex = 1; revealCardNum = null" class="mr-1 mt-2" depressed>
                      Back
                    </v-btn>
                  </div>
                  <h2 style="margin-top: 20px">Data Overview</h2>
                </div>
                <div class="flex-box">
                  <div style="max-width: 550px">
                    <p>
                      The below visualization shows how abundant each bacteria
                      is for each sample.
                    </p>
                    <p>
                      When it comes to microbiome data however, having
                      <strong>more</strong> of a certain bug doesn't mean that
                      it is necessarily <strong>significant</strong>. To measure
                      significance, you can conduct a univariate analysis under the "Start your analysis" tab.
                    </p>
                  </div>
                  <div style="margin-left: auto; background-color: #D4E1E4; padding: 10px; border-radius: 5px">
                    <div class="flex-box">
                      <v-icon color="primary" style="align-items:flex-start" class="hide-on-mobile" large>mdi-chat-question</v-icon>
                      <div class="info-box-text">
                        <h3 style="margin-top: 2px">What is abundance?</h3>
                        <p>For each study, we calculate a value that represents how much of this bacteria is present for a given sample. Zero values are depicted with small lines, meaning the bacteria is not present at all. If you add up all of the abundance values in a column, you get 100% of the bacteria found in a sample.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style="margin-top: 50px">
                  <div>
                    <div class="d-flex justify-space-between">
                      <div style="font-size: 24px; padding: 8px" >
                        <span>{{ datasetNames[selectedDataset] }} et al. </span>
                        collected
                        <strong>{{ numSamples }} samples</strong> across
                        <strong>{{ numDiseaseStates }} disease states</strong>.
                      </div>
                      <div class="pa-2" style="width: 300px">
                        <h4>Disease States</h4>
                        <MiniStackedBar
                          v-if="diseaseStateBreakdown != null"
                          :data="diseaseStateBreakdown"
                          :colorPalette="colorPalette"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="d-flex" id="bubble-matrix-filters">
                      <VSelect
                        :items="taxaNames"
                        v-model="selectedTaxa"
                        item-text="label"
                        item-value="value"
                        label="Select a taxonomic level"
                        style="max-width: 200px"
                      ></VSelect>
                      <!-- <div v-if="!isMobile" style="max-width: 500px"> -->
                      <v-checkbox
                        v-if="!isMobile"
                        v-model="diseaseStateCheckbox"
                        label="Group within disease state"
                        style="
                          border-right: solid 1px gray;
                          border-left: solid 1px gray;
                          padding-right: 20px;
                          margin-left: 10px;
                          max-height: 40px;
                          margin-top: 8px;
                        "
                      ></v-checkbox>
                      <div
                        v-if="selectedBacteriaFromChart != null && !isMobile"
                        style="color: rgba(0, 0, 0, 0.6); margin-top: 8px"
                      >
                        Sorting by
                        <strong>{{ selectedBacteriaFromChart }}</strong>
                      </div>
                      <v-expansion-panels
                        accordion
                        id="population-select-panel"
                        style="margin-top: -8px"
                      >
                        <v-expansion-panel v-for="(item, i) in 1" :key="i">
                          <v-expansion-panel-header color="#D4E1E4"
                            >Start your analysis</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <div
                              style="margin-left: auto; margin-right: 0"
                              id="population-select"
                              class="pa-1 mt-1"
                            >
                            <br>
                              <p>
                                Assign unique groups to two separate populations
                                to conduct a univariate analysis.
                              </p>

                              <h4>Population 1</h4>
                              <div>
                                <v-select
                                  v-model="selectedDiseasePop1"
                                  :items="availDiseaseStates1"
                                  attach
                                  chips
                                  label="Select one or more groups"
                                  multiple
                                  style="max-width: 400px"
                                ></v-select>
                              </div>
                              <div>
                                <h4>Population 2</h4>
                                <v-select
                                  v-model="selectedDiseasePop2"
                                  :items="availDiseaseStates2"
                                  attach
                                  chips
                                  label="Select one or more groups"
                                  multiple
                                  style="max-width: 400px"
                                ></v-select>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      color="primary"
                                      dark
                                      v-bind="attrs"
                                      v-on="on"
                                      style="margin-bottom: 3px; margin-right: 5px"
                                    >
                                      mdi-information
                                    </v-icon>
                                  </template>
                                  <span>False Discovery Rate (FDR) is a measure of accuracy when multiple hypotheses are being tested at once</span>
                                </v-tooltip>
                                <span style="font-size: 16px">Significance threshold (<a target="_blank" href="https://www.publichealth.columbia.edu/research/population-health-methods/false-discovery-rate">False Discovery Rate</a>)</span>
                                <v-slider
                                v-model="selectedThreshold"
                                step="0.01"
                                min="0.01"
                                max="0.2"
                                thumb-label
                                ticks="always"
                                tick-size="2"
                              >
                              <template v-slot:append>
                                  <v-text-field
                                    v-model="selectedThreshold"
                                    class="mt-0 pt-0"
                                    hide-details
                                    single-line
                                    type="number"
                                    style="width: 60px"
                                  ></v-text-field>
                                </template>
                              </v-slider>
                                <v-btn
                                  @click="
                                    stepIndex = 3;
                                    startUnivariateTest();
                                  "
                                  v-if="
                                    selectedDiseasePop1.length != 0 &&
                                    selectedDiseasePop2.length != 0
                                  "
                                  class="mt-4"
                                  color="primary"
                                  style="float: right; margin-bottom: 20px"
                                >
                                  Run analysis
                                </v-btn>
                              </div>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </div>
                    <BubbleMatrix
                      v-if="colorPalette != null && stepIndex == 2"
                      @bacteriaClicked="onBacteriaClickFromMatrix"
                      @groupedMedianDataSender="onMedianDataReceived"
                      :taxa="selectedTaxa"
                      :currentDataset="currentDataset"
                      :currentMetadata="currentMetadata"
                      :colorPalette="colorPalette"
                      :parentResized="resizeWatcher"
                      :transposedListener="transposed"
                      :constrainedView="constrainBubbleChart"
                      :dataset="selectedDataset"
                      :patients="patients"
                      :groupSortByDisease="diseaseStateCheckbox"
                      :mobileListener="isMobile"
                    />
                  </div>
                </div>
              </div>
            </v-stepper-content>
            <v-stepper-content style="min-height: 800px" step="3">
              <LoadingSplash :progressTracker="1" v-if="analysisIsLoading" :factRefreshCounter="factCounter" />
              <div class="flex-box">
                <v-btn
                  @click="
                    (stepIndex = 2),
                      (analysisDataResponse = null),
                      circlePlotKey++,
                      resetPopulations(),
                      factCounter++
                  "
                  class="mobile-margin"
                  depressed
                >
                  Back
                </v-btn>
              </div>
              <br />
              <p v-if="isMobile">
                Here are the results from your univariate analysis. For each
                bacteria, the analysis calculates a
                <strong>significance value</strong>, with lower values
                representing a greater potential significance. Each bacteria card
                also includes <strong>median abundance</strong> and
                <strong>prevalence</strong> values for each selected population
                group.
              </p>
              <!-- the key of circleplot updates every time a new analysis runs
              which Vue treats as a new component (so mount runs again) -->
              <div id="circle-plot-parent">
              <CirclePlot
                v-if="analysisDataResponse != null"
                :key="circlePlotKey"
                :abundanceData="currentDataset"
                :significanceData="analysisDataResponse"
                :patients="patients"
                :metadata="currentMetadata"
                :parentResized="resizeWatcher"
                :mobileListener="isMobile"
                :popOne="selectedDiseasePop1"
                :popTwo="selectedDiseasePop2"
                :sigThreshold="selectedThreshold"
              />
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </v-main>
    <v-footer id="footer">
      <p style="text-align: center; display:block; margin: 0 auto; font-size: 12px">© 2023 | <a style="color: white" href="https://microbiome.mit.edu/">Center for Microbiome Informatics & Therapeutics</a>  (CMIT)</p>
    </v-footer>
  </v-app>
</template>

<script>
import BubbleMatrix from "./components/BubbleMatrix.vue";
import MiniStackedBar from "./components/MiniStackedBar.vue";
import CirclePlot from "./components/CirclePlot.vue";
import LoadingSplash from "./components/LoadingSplash.vue";
import * as d3 from "d3";

export default {
  name: "App",
  components: {
    BubbleMatrix,
    MiniStackedBar,
    CirclePlot,
    LoadingSplash
  },
  data() {
    return {
      factCounter: 0,
      diseaseStateCheckbox: true,
      diseaseCategory: [
        {
          "name": "Inflammatory Bowel Disease (IBD)",
          "prefix": "ibd",
          "authors": [
          {
              name: "Alm",
              title: "Non-Invasive Mapping of the Gastrointestinal Microbiota Identifies Children with Inflammatory Bowel Disease",
              doi: "http://dx.doi.org/10.1371/journal.pone.0039242"
            }, 
            {
              name: "Huttenhower",
              title: "Dysfunction of the intestinal microbiome in inflammatory bowel disease and treatment",
              doi: "http://dx.doi.org/10.1016/j.chom.2014.02.005"
            }, 
            {
              name: "Engstrand & Maxee",
              title: "A Pyrosequencing Study in Twins Shows That Gastrointestinal Microbial Profiles Vary With Inflammatory Bowel Disease Phenotypes",
              doi: "http://dx.doi.org/10.1053/j.gastro.2010.08.049"
            }, 
            {
              name: "Gevers",
              title: "The treatment-naive microbiome in new-onset crohn’s disease",
              doi: "http://dx.doi.org/10.1016/j.chom.2014.02.005"
            },
          ]
        },
        {
          "name": "C. diff (CDI)",
          "prefix": "cdi",
          "authors": [
            {
              name: "Vincent",
              title: "Reductions in intestinal clostridiales precede the development of nosocomial clostridium difficile infection",
              doi: "http://dx.doi.org/10.1186/2049-2618-1-18"
            },
            {
              name: "Schubert",
              title: "Microbiome data distinguish patients with clostridium difficile infection and non-c. difficile-associated diarrhea from healthy controls",
              doi: "http://dx.doi.org/10.1128/mBio.01021-14"
            },
            {
              name: "Youngster",
              title: "Fecal microbiota transplant for relapsing clostridium difficile infection using a frozen inoculum from unrelated donors",
              doi: "http://dx.doi.org/10.1093/cid/ciu135"
            },
          ]
        },
        // {
        //   "name": "Autism",
        //   "prefix": "autism",
        //   "authors": [
        //     {
        //       name: "Kang",
        //       title: "Reduced Incidence of Prevotella and Other Fermenters in Intestinal Microflora of Autistic Children",
        //       doi: "http://dx.doi.org/10.1371/journal.pone.0068322"
        //     }
        //   ]
        // },
        {
          "name": "HIV",
          "prefix": "hiv",
          "authors": [
            {
              name: "Lozupone",
              title: "Alterations in the gut microbiota associated with hiv-1 infection",
              doi: "http://dx.doi.org/10.1016/j.chom.2013.08.006"
            },
            // {
            //   name: "Dinh",
            //   title: "Intestinal microbiota, microbial translocation, and systemic inflammation in chronic HIV infection",
            //   doi: "http://dx.doi.org/10.1093/infdis/jiu409"
            // },
            {
              name: "Noguera-Julian",
              title: "Gut microbiota linked to sexual preference and hiv infection",
              doi: "https://doi.org/10.1016%2Fj.ebiom.2016.01.032"
            }
          ]
        },
        {
          "name": "Diabetes (Type 1)",
          "prefix": "t1d",
          "authors": [
          // {
          //     name: "Alkanani",
          //     title: "Alterations in intestinal microbiota correlate with susceptibility to type 1 diabetes",
          //     doi: "http://dx.doi.org/10.2337/db14-1847"
          //   },
            {
              name: "Mejialeon",
              title: "Fecal microbiota imbalance in Mexican children with type 1 diabetes",
              doi: "http://dx.doi.org/10.1038/srep03814"
            }
          ]
        },
        {
          "name": "Parkinsons",
          "prefix": "par",
          "authors": [
          {
              name: "Scheperjans",
              title: "Gut microbiota are related to parkinson’s disease and clinical phenotype",
              doi: "http://dx.doi.org/10.1002/mds.26069"
            }
          ]
        },
        {
          "name": "Eosinophilic digestive disease (EDD)",
          "prefix": "edd",
          "authors": [
          {
              name: "Singh",
              title: "Intestinal microbial communities associated with acute enteric infections and disease recovery",
              doi: "http://dx.doi.org/10.1186/s40168-015-0109-2"
            }
          ]
        },
        {
          "name": "Obesity",
          "prefix": "ob",
          "authors": [
          // {
          //     name: "Ross",
          //     title: "16s gut community of the cameron county hispanic cohort",
          //     doi: "http://dx.doi.org/10.1186/s40168-015-0072-y"
          //   },
            {
              name: "Gordon",
              title: "A core gut microbiome in obese and lean twins",
              doi: "http://dx.doi.org/10.1038/nature07540"
            }
          ]
        },
    ],
    datasetNames: {
        ibd_alm: "Alm",
        ibd_engstrand_maxee: "Engstrand & Maxee",
        ibd_gevers_2014: "Gevers",
        ibd_huttenhower: "Huttenhower",
        autism_kb: "Kang",
        hiv_dinh: "Dinh",
        hiv_lozupone: "Lozupone",
        hiv_noguerajulian: "Noguera-Julian",
        t1d_alkanani: "Alkanani",
        t1d_mejialeon: "Mejialeon",
        par_scheperjans: "Scheperjans",
        edd_singh: "Singh",
        cdi_vincent_v3v5: "Vincent",
        cdi_schubert: "Schubert",
        cdi_youngster: "Youngster",
        ob_ross: "Ross",
        ob_gordon_2008_v2: "Gordon"
        // zackular: "Zackular"
      },
      revealCardNum: null,
      diseaseStates: null,
      openPanel: null,
      currentTab: 0,
      stepIndex: 1,
      isMobile: false,
      analysisIsLoading: false,
      analysisDataResponse: null,
      circlePlotKey: 1,
      taxaNames: [
        { value: "d", label: "denovo" },
        { value: "g", label: "genus" },
        { value: "f", label: "family" },
        { value: "c", label: "class" },
        { value: "o", label: "order" },
        { value: "p", label: "phylum" },
      ],
      chip1: true,
      selectedBacteriaFromChart: null,
      selectedDataset: "ibd_alm",
      selectedDiseasePop1: [],
      availDiseaseStates1: null,
      selectedDiseasePop2: [],
      availDiseaseStates2: null,
      selectedTaxa: "f",
      selectedThreshold: 0.05,
      currentDataset: null,
      patients: null,
      currentMetadata: null,
      transposedData: null,
      numSamples: 0,
      numDiseaseStates: 0,
      diseaseStateBreakdown: null,
      availableMetadata: null,
      sidebarOpen: true,
      resizeWatcher: 0,
      transposed: false,
      constrainBubbleChart: true,
      pyodide: null,
      pyodideLoaded: false,
      colors: ["#2c617c", "#f6a704",  "#60a843", "#008a84"],
      colorPalette: null,
      medianDataset: null,
    };
  },
  watch: {
    selectedDataset() {
      this.processDatasets();
    },
    selectedTaxa() {
      this.selectedBacteriaFromChart = null;
    },
    // TODO: 2. can we just gray out options instead of removing from dropdown list?
    // solution in this codepen: https://codepen.io/typerory/pen/LgNpWw
    // will need to refactor the following two watchers to update the disabled data 
    // instead of removing the items from the availDiseaseStates arrays
    selectedDiseasePop1(newVals) {
      // for each item in selected pop 1 arr, check to see if this item is in selectedDiseasePop2
      // if it is not selected, then remove this item from the available disease list for 2
      if (this.stepIndex == 2) {
        newVals.forEach((d) => {
          if (!this.selectedDiseasePop2.includes(d)) {
            this.availDiseaseStates2 = this.availDiseaseStates2.filter(
              (item) => item !== d
            );
          }
        });
        if (newVals.length == 0) {
          this.availDiseaseStates2 = this.diseaseStates;
        }
      }
    },
    selectedDiseasePop2(newVals) {
      // same functionality as above, but listening for changes in pop 2
      if (this.stepIndex == 2) {
        newVals.forEach((d) => {
          if (!this.selectedDiseasePop1.includes(d)) {
            this.availDiseaseStates1 = this.availDiseaseStates1.filter(
              (item) => item !== d
            );
          }
        });

        if (newVals.length == 0) {
          this.availDiseaseStates1 = this.diseaseStates;
        }
      }
    },
  },
  mounted() {
    this.processDatasets();
    this.checkDimensions();
    window.addEventListener("resize", this.checkDimensions);
  },
  methods: {
    onMedianDataReceived(val) {
      console.log(val)
    },
    resetPopulations() {
      this.availDiseaseStates1 = this.diseaseStates;
      this.availDiseaseStates2 = this.diseaseStates;
    },
    // take the first two disease states from all disease states
    // to prepopulate the dropdowns for the analysis
    prefillPopSelection() {
      this.selectedDiseasePop1 = [];
      this.selectedDiseasePop1.push(this.diseaseStates[0]);

      this.selectedDiseasePop2 = [];
      this.selectedDiseasePop2.push(this.diseaseStates[1]);
    },
    checkDimensions() {
      // we only account for two layouts: desktop and mobile
      // if less than 800px, enforce mobile layout by updating isMobile data variable
      if (document.documentElement.clientWidth < 800) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      // increment the resize watcher variable (some components use this as a "listener" to know when to resize)
      this.resizeWatcher++;
    },
    updateDatasetName(name) {
      function getKeyByValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value);
      }
      let dataObj = getKeyByValue(this.datasetNames, name);
      this.selectedDataset = dataObj;
    },
    removeSelectedBacteria() {
      // turn off the pill that displays when user sorts matrix
      this.selectedBacteriaFromChart = null;
      this.chip1 = false;
    },
    onBacteriaClickFromMatrix(value) {
      // turn on the pill and popualte it with bacteria name
      this.selectedBacteriaFromChart = value;
      this.chip1 = true;
    },
    processDatasets() {
      console.log(this.selectedDataset)
      const fPath = "./data/" + this.selectedDataset + ".csv";
      const mPath = "./data/" + this.selectedDataset + "_meta.csv";
      
      Promise.all([
        d3.csv(fPath),
        d3.csv(mPath),
        d3.json("./data/" + this.selectedDataset + "_transposed.json"), // need this version of dataset for the univariate test
      ]).then((datasets) => {
        // store all data in Vue vars
        this.currentDataset = datasets[0];
        this.currentMetadata = datasets[1];
        this.transposedData = datasets[2];

        // calculate some data to display in the sidebar
        // will also pass these values into the stacked bar chart of disease states
        this.numSamples = this.currentMetadata.length;
        let uniqueDiseaseStates = [
          ...new Set(this.currentMetadata.map((item) => item.DiseaseState)),
        ];
        this.numDiseaseStates = uniqueDiseaseStates.length;
        this.diseaseStates = uniqueDiseaseStates;
        this.resetPopulations();

        // create color map dictionary for disease states
        let obj = {};
        this.diseaseStates.forEach((d, i) => {
          obj[d] = this.colors[i];
        });
        this.colorPalette = obj;
        console.log(this.colorPalette)

        // add up number of samples under each disease state
        const counts = this.currentMetadata.reduce(
          (counts, { DiseaseState }) => {
            counts[DiseaseState] = (counts[DiseaseState] || 0) + 1;
            return counts;
          },
          {}
        );
        this.diseaseStateBreakdown = counts;

        // create list of all available metadata fields for specific study
        this.availableMetadata = Object.keys(this.currentMetadata[0]);

        // create list of all patient IDs
        this.patients = Object.keys(this.currentDataset[0]);

        // filter this list to remove any empty fields
        this.patients = this.patients.filter((item) => item !== "");

        // NOTE: assumption is that the first column of each datasets stores "sample"ë
        this.currentMetadata.forEach((d) => {
          d.patient = d[this.currentMetadata.columns[0]]
        })

        // index has long concatenated taxa strings
        // loop thru and split these into different key/obj pairs
        this.currentDataset.forEach((d) => {
          let name, val;

          d[""].split(";").forEach((j) => {
            name = "taxa_" + j.split("__")[0];
            val = j.split("__")[1];

            if (val.length > 24) {
              // truncate string to length 20
              val = val.substring(0, 18) + "...";
            }

            if (val.length == 0) {
              val = "Unknown"
            }

            d[name] = val;
          });
        });
      });
    },
    startUnivariateTest() {
      console.log("starting uinvariate test", this.circlePlotKey);
      this.analysisIsLoading = true;
      
      // call a new web worker to run the python script
      var pyodideWorker = new Worker("/workers/webworker.js");

      pyodideWorker.onerror = (e) => {
        console.log(
          `Error in pyodideWorker at ${e.filename}, Line: ${e.lineno}, ${e.message}`
        );
      };

      pyodideWorker.onmessage = (e) => {
        const { results, error } = e.data;
        // if successfully return results
        if (results) {
          // parse them into JSON
          const obj = JSON.parse(results);
          console.log(obj);
          // store in data var and pass down into viz component CirclePlot
          this.analysisDataResponse = obj;

          this.analysisIsLoading = false;
        } else if (error) {
          console.log("pyodideWorker error: ", error);
        }
      };

      // passing data in as strings cause pyodide doesn't like Objects
      const data = {
        dataFromJs: JSON.stringify(this.transposedData),
        metadata: JSON.stringify(this.currentMetadata),
        case_from_js: this.selectedDiseasePop1.toString(),
        controls_from_js: this.selectedDiseasePop2.toString(),
        python: "hi"
      };

      // send data object into pyodide
      pyodideWorker.postMessage(data);
    },
  },
};
</script>

<style>
@import "./assets/globalstyles.css";

#app {
  font-family:  Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  color: darkslategray;
  /* light blue */
  /* background-color: #d5e1e4; */
  background-color: rgba(255,255,255,1);
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
}

.gradient-banner {
  background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 25%, rgba(255,255,255,0.4) 50%,rgba(255,255,255,0));
}

#banner {
  display: flex;
  gap: 10px;
  flex-direction: row-reverse
}

#banner h1 {
  font-size: 3rem;
  width: 40vw;
  margin-top: 70px;
  padding-left: 40px
}

#banner-text-wrapper {
  padding: 20px;
}

.top-card-container {
  margin-top: 50px !important; 
  margin-bottom: 70px !important;
}

p {
  font-size: 18px;
}

#stepper-container {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%) !important
}
.b-container {
  max-width: 1500px;
  margin: 0 auto;
  padding: 10px
}

.top-cards {
  padding-bottom: 40px;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.top-cards i {
  font-size: 62px !important;
  margin-bottom: 20px
}

.flex-cards {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

.top-card-section {
  height: 130px
}

.sidebar-layout {
  display: grid;
  grid-template-columns: 1fr minmax(150px, 25%);
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.v-expansion-panel-header {
  font-size: 1.2rem;
  font-weight: 600;
}

.neu-shadow {
  border: 2px solid black;
  background-color: white;
}

.right-align-btn {
  display: block;
  margin-right: 0;
  margin-left: auto;
  float: right;
}

.margin-desktop-only {
  margin: 10px;
}

#header {
  background-color: aliceblue;
  height: 70px;
  padding: 10px;
  border-bottom: 2px solid black;
}

#footer {
  background-color: darkslategrey;
  color: white;
  padding: 10px
}

#sidebar {
  background-color: white;
  box-shadow: 0.2rem 0.2rem black;
  border: 2px solid black;
  margin: 10px;
}

#sidebar-header h3 {
  display: inline;
}

#sidebar-header span {
  float: right;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}

#portal-container {
  display: grid;
  grid-template-columns: minmax(200px, 20%) 1fr;
}

.study-title {
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 12px;
  display: inline-block;
  max-width: 80%;
  font-style: italic;
}

.db-icon {
  font-size: 40px;
    display: inline-block;
    position: absolute !important;
    top: 10px;
    right: 10px;
}

.doi-link {
  color: #adc3cf !important;
  display: inline-block;
  float: right;
  margin-top: 10px
}

.author-name {
  display: inline-block;
  margin-top: 10px
}

.doi-link i {
  color: #adc3cf !important;
  margin-left: 4px;
}

.doi-link a{
  color: #adc3cf !important
}

.show-sidebar {
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg); /*  for IE  */

  /* 	for browsers supporting webkit (such as chrome, firefox, safari etc.). */
  -webkit-transform: rotate(-90deg);
  display: block;
  float: left;
  cursor: pointer;
  font-size: 14px;
  margin-left: -10px;
  margin-top: 10px;
  text-decoration: underline;
}

.filter-item {
  margin-top: 20px;
  margin-bottom: 10px;
}

.stat {
  font-size: 2rem;
  margin-top: 20px;
}

#bubble-matrix-filters {
  margin-top: 20px;
  margin-bottom: 30px;
}

#bubble-matrix-filters > div {
  display: inline-block;
  padding: 10px;
}

#transpose-btn {
  height: 40px;
  position: relative;
  cursor: pointer;
}

#transpose-btn span {
  font-size: 10px;
  display: inline-block;
  padding-left: 5px;
  position: absolute;
  top: 20%;
}

#transpose-btn img {
  display: inline-block;
}

.flex-box {
  display: flex;
}

#population-select-panel {
  max-width: 500px;
  margin-left: auto;
}

.info-box-text {
  max-width: 500px;
  margin-right: 30px;
  margin-left: 8px
}

.info-box-text p {
  font-size: 16px;
  margin-top: 8px
}

#stepper-wrapper {
  padding: 10px;
  background-color: #d5e1e4;
  padding-top: 60px
}

#navbar-wrapper {
  height: 80px
}

#navbar-title {
  display: inline-block; margin-left: 48px; margin-top: 6px; font-size: 36px
}

#navbar-logo {
  margin-top: -4px
}

#navbar-icon {
  position: absolute; top: 14px
}

/* SPECIFIC MEDIA QUERIES FOR RESPONSIVE BANNER TEXT */
@media screen and (max-width: 1200px) {
  #banner h1 {
    font-size: 2.5rem;
    width: 40vw;
    margin-top: 20px;
  }
}

@media screen and (max-width: 950px) {
  #banner h1 {
    font-size: 2.5rem;
    width: 50vw;
    margin-top: 20px;
  }
}

@media screen and (max-width: 750px) {
  #banner h1 {
    font-size: 2rem;
    width: 50vw;
    margin-top: 10px;
  }
}

/* OVERRIDE DEFAULT CONTAINER BEHAVIOR FROM VUETIFY */
@media screen and (min-width: 1264px) {
  .container {
      max-width: 100%;
  }
}
@media screen and (min-width: 960px) {
  .container {
      max-width: 100%;
  }
}

/* GENERIC MEDIA QUEIRES FOR BREAK POINT AT 700PX */
@media screen and (max-width: 700px) {
  body {
    overflow-x: hidden;
    width: 100%
  }

  .disease-panel-headers {
    font-size: 16px
  }

  .margin-desktop-only {
    margin: 0px;
  }
  /* override the vuetify default for panel on mobile */
  .v-expansion-panel-content__wrap {
    padding: 0px;
  }

  .flex-cards {
    margin: 8px;
  }

  .top-card-section {
    height:auto
  }

  #banner {
    display: block
  }

  #navbar-title {
    font-size: 26px;
    margin-top: -2px;
    margin-left: 35px
  }

  #navbar-icon {
    top: 12px;
    left: 20px;
    font-size: 30px !important
  }

  #navbar-logo {
    display: none
  }

  #stepper-wrapper {
    padding: 0px;
  }

  .hide-on-mobile {
    display: none !important
  }
  
  #banner-text-wrapper {
    position: absolute;
    top: 110px;
    padding: 10px
  }

  #banner h1 {
    font-size: 2rem;
    width: 100vw;
    margin-top: 0px;
    padding-left: 15px
  }

  .gradient-banner {
    background: linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 25%, rgba(255,255,255,0.5) 70%,rgba(255,255,255,0));
  }

  .sidebar-layout {
    display: block;
  }

  .right-align-btn.mobile-margin {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .v-application .d-flex {
    display: block !important;
  }

  .flex-box {
    display: block;
  }

  #population-select {
    padding: 10px !important;
    height: 430px;
    margin-bottom: 10px;
  }

  #app {
    padding: 0px;
  }

  .v-stepper__content {
    padding: 12px;
  }
}
</style>
