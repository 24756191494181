<template>
  <div>
    <!-- NORMAL CIRCLE PLOT VIZ -->
    <div v-if="!mobileListener" id="circle-plot-container">
      <div class="flex-div">
        <div class="flex-2">
          <h2>Univariate analysis results</h2>
          <p style="max-width: 600px">
            Each circle represents a bacteria.
            <v-icon color="#111111" small>mdi-chart-bubble</v-icon>
            <strong> Larger circles</strong> have higher median abundance for a
            disease state. Circles with
            <v-icon color="#111111" small>mdi-brightness-6</v-icon>
            <strong> darker colors</strong> have higher presence.
            <v-icon color="#111111" small>mdi-cursor-default</v-icon>
            Hover to discover more.
          </p>
        </div>
        <div class="flex" style="max-width: 400px; margin: 10px">
          <span style="font-size: 16px"
            >Significance threshold (<a
              target="_blank"
              href="https://www.publichealth.columbia.edu/research/population-health-methods/false-discovery-rate"
              >False Discovery Rate</a
            >)</span
          >
          <v-slider
            v-model="localSigThreshold"
            step="0.01"
            min="0.01"
            max="0.2"
            thumb-label
            ticks="always"
            tick-size="3"
          >
            <template v-slot:append>
              <v-text-field
                v-model="localSigThreshold"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              ></v-text-field>
            </template>
          </v-slider>
          <v-btn
            @click="refilterData()"
            class="mt-4"
            color="primary"
            style="float: right; margin-bottom: 20px"
          >
            Re-run analysis
          </v-btn>
        </div>
      </div>

      <h3 v-if="currentDataset != null">Your analysis returned {{ currentDataset.length }} bacteria</h3>

      <!-- only show if 0 length for currentDataset -->
      <div id="alert-text">
        <v-icon style="display: inline-block" color="secondary" x-large
          >mdi-comment-alert</v-icon
        >
        <p style="display: inline-block; margin-left: 10px; margin-top: 20px">
          Your analysis returned zero results that were under a false discovery
          rate of {{ localSigThreshold }}. Try adjusting the
          threshold to a higher value before restarting the analysis. If no results are returned at the highest threshold, there is likely a low significance between the two populations.
        </p>
      </div>

      <div id="circle-legend" style="float: right; width: 200px">
        <div style="display: block; font-size: 12px; margin-bottom: 4px">
          Size = median abundance
        </div>
        <div class="bubble-legend-item" style="width: 10px; height: 10px"></div>
        <div class="bubble-legend-item" style="width: 18px; height: 18px"></div>
        <div
          class="bubble-legend-item"
          style="border-radius: 25px; width: 30px; height: 30px"
        ></div>
      </div>
    </div>
    <!-- MOBILE ALTERNATIVE TO CIRCLE PLOT BELOW -->
    <div v-else>
      <div>
        <br />
        <hr style="margin-bottom: 40px" />
        <div id="mobile-alert-text">
          <v-icon style="display: inline-block" color="secondary" x-large
            >mdi-comment-alert</v-icon
          >
          <p style="display: inline-block;">
            Your analysis returned zero results that were under a false discovery
            rate of {{ localSigThreshold }}. Try adjusting the
            threshold to a higher value before restarting the analysis. If no results are returned at the highest threshold, there is likely a low significance between the two populations.
          </p>
        </div>
        
        <v-expansion-panels class="mb-6 mt-4" style="margin-top: 20px">
          <v-expansion-panel>
            <v-expansion-panel-header
              color="#D4E1E4"
              class="black--text"
              style="font-size: 16px"
            >
              What do these categories mean?
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mt-2 definition-panel pt-4">
              <p>
                <strong>ABUNDANCE:</strong> the median amount of this bacteria
                for a given population.
              </p>
              <p>
                <strong>PREVALENCE:</strong> the number of times this bacteria
                was found in a population.
              </p>
              <p>
                <strong>SIGNIFICANCE:</strong> the statistical likelihood that
                this bacteria is meaningfully different between the populations.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <br />
        <h2>Show me the most...</h2>

        <v-row justify="center">
          <v-col cols="12" sm="12" md="12" lg="12" class="pa-0 pt-2 mt-2">
            <v-sheet elevation="0">
              <div class="pa-4">
                <v-chip-group
                  active-class="primary--text"
                  column
                  mandatory
                  v-model="selectedSortType"
                >
                  <v-chip
                    v-for="tag in mobileFilterTags"
                    :key="tag"
                    filter
                    medium
                    color="primary"
                    outlined
                    style="font-size: 12px"
                  >
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
      <v-card
        dark
        class="mx-auto mb-6"
        color="primary"
        v-for="(d, i) in currentDataset"
        :key="i"
      >
        <v-card-text style="padding: 12px 12px 0px 12px">
          <div style="display: inline-block">{{ d.taxa_d }}</div>
          <div>
            <h2 v-if="d.taxa_g.length != 0" class="white--text mb-2 pb-0" style="display: inline-block">
              {{ d.taxa_g }}
            </h2>
            <h2 v-else class="white--text mb-2 pb-0" style="display: inline-block">
              Unknown genus
            </h2>
            <!-- NOTE: Ask Eric if we should use Exponential or not -->
            <!-- <span style="float: right">Significance: {{ (+d.value).toFixed(3) }}</span> -->
            <span style="float: right"
              >Significance: {{ (+d.value).toExponential(3) }}</span
            >
          </div>
          <hr />
          <div class="flex-div mt-2">
            <div class="flex-1-fixed"></div>
            <div class="flex-2">ABUNDANCE</div>
            <div class="flex-2">PREVALENCE</div>
          </div>
          <div class="flex-div">
            <div class="flex-1-fixed">{{ Object.keys(d.abundance[0])[0] }}</div>
            <div class="flex-2 pr-2">
              <!-- <v-progress-linear rounded color="secondary" :value="+Object.values(d.abundance[0])[0]*100"></v-progress-linear> -->
              <div class="orange-stat">
                {{ (+Object.values(d.abundance[0])[0] * 100).toFixed(2) + "%" }}
              </div>
            </div>
            <div class="flex-2 pr-2 pt-2 pb-2">
              <v-progress-linear
                height="5"
                color="secondary"
                :value="+Object.values(d.zeroRatio[0])[0] * 100"
              ></v-progress-linear>
              <span>{{
                (+Object.values(d.zeroRatio[0])[0] * 100).toFixed(2) +
                "% of samples"
              }}</span>
            </div>
          </div>
          <div class="flex-div">
            <div class="flex-1-fixed">{{ Object.keys(d.abundance[1])[0] }}</div>
            <div class="flex-2 pr-2">
              <!-- <v-progress-linear color="secondary" :value="+Object.values(d.abundance[1])[0]*100"></v-progress-linear> -->
              <div class="orange-stat">
                {{
                  (+Object.values(d.abundance[1])[0] * 100).toFixed(2) + "% "
                }}
              </div>
            </div>
            <div class="flex-2 pr-2 pt-2 pb-2">
              <v-progress-linear
                height="5"
                color="secondary"
                :value="+Object.values(d.zeroRatio[1])[0] * 100"
              ></v-progress-linear>
              <span>{{
                (+Object.values(d.zeroRatio[1])[0] * 100).toFixed(2) +
                "%  of samples"
              }}</span>
            </div>
          </div>
        </v-card-text>

     
      </v-card>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "CirclePlot",
  props: {
    abundanceData: {
      required: true,
    },
    significanceData: {
      required: false,
      type: Object,
    },
    patients: {
      required: true,
      type: Array,
    },
    popOne: {
      required: true,
      type: Array,
    },
    popTwo: {
      required: true,
      type: Array,
    },
    metadata: {
      required: true,
      type: Array,
    },
    colorPalette: {
      required: false,
      type: Object,
    },
    mobileListener: {
      required: true,
      type: Boolean,
    },
    parentResized: {
      required: false,
      type: Number,
    },
    sigThreshold: {
      required: false,
      type: Number,
      default: 0.05,
    },
  },
  data() {
    return {
      patientDiseaseStates: {}, // keys are patient IDs, values are disease state strings
      groupedAbundanceData: null,
      selectedDiseaseStates: null,
      maxMedianBugVal: null,
      currentDataset: null,
      mobileFilterTags: ["ABUNDANT", "PREVALENT", "SIGNIFICANT"],
      selectedSortType: null,
      localSigThreshold: null,
    };
  },
  watch: {
    parentResized() {
      if (!this.mobileListener) {
        d3.select("svg").remove();
        this.drawViz();
      } else {
        d3.select("svg").remove();
      }
    },
    selectedSortType(val) {
      this.sortDatasetBy();
    },
  },
  mounted() {
    
    this.localSigThreshold = this.sigThreshold;

    this.groupAbundanceData();

    // reformats the response from analysis to fit data format needed by d3
    this.parseSigData();
    

    
      if (!this.mobileListener && this.currentDataset.length > 0) {
      console.log('data is present')
      this.drawViz();
    } else if (!this.mobileListener && this.currentDataset.length ==0) {
      console.log('no data on desktop')
      document.getElementById("alert-text").style.display = "block";
    } else if (this.mobileListener && this.currentDataset.length ==0) {
      document.getElementById("mobile-alert-text").style.display = "block";
    } else {
      console.log("unknown error!")
    }
    
  },
  methods: {
    refilterData() {
      d3.select("svg").remove();
      this.parseSigData();

      if (!this.mobileListener && this.currentDataset.length > 0) {
      console.log('data is present')
      this.drawViz();
    } else if (!this.mobileListener && this.currentDataset.length ==0) {
      console.log('no data on desktop')
      document.getElementById("alert-text").style.display = "block";
    } else if (this.mobileListener && this.currentDataset.length ==0) {
      document.getElementById("mobile-alert-text").style.display = "block";
    } else {
      console.log("unknown error!")
    }
    },
    sortDatasetBy() {
      console.log(this.currentDataset);
      let variable = this.mobileFilterTags[this.selectedSortType];
      if (variable == "SIGNIFICANT") {
        this.currentDataset.sort((a, b) => {
          return a["q"] - b["q"];
        });
      } else if (variable == "PREVALENT") {
        let val1, val2;
        this.currentDataset.sort((a, b) => {
          // first grab both values in the array for a, and subtract to compare (do same for b)
          val1 =
            Object.values(a.zeroRatio[1])[0] - Object.values(a.zeroRatio[0])[0];
          val2 =
            Object.values(b.zeroRatio[1])[0] - Object.values(b.zeroRatio[0])[0];
          // reassign as absolute values
          val1 = Math.abs(val1);
          val2 = Math.abs(val2);
          // pass to sort to see if positive or negative
          return val2 - val1;
        });
      } else if (variable == "ABUNDANT") {
        let val1, val2;
        this.currentDataset.sort((a, b) => {
          // first grab both values in the array for a, and subtract to compare (do same for b)
          val1 =
            Object.values(a.abundance[1])[0] - Object.values(a.abundance[0])[0];
          val2 =
            Object.values(b.abundance[1])[0] - Object.values(b.abundance[0])[0];
          // reassign as absolute values
          val1 = Math.abs(val1);
          val2 = Math.abs(val2);
          // pass to sort to see if positive or negative
          return val2 - val1;
        });
      }
      console.log(this.selectedSortType);
    },
    parseSigData() {
      let data = [];

      // for every entry in the "q" object, pull out the name (key) and the value
      Object.keys(this.significanceData.q).forEach((d, i) => {
        let obj = {};
        
        obj[""] = d;
        obj.q = Object.values(this.significanceData.q)[i];
        obj.p = Object.values(this.significanceData.p)[i];
        data.push(obj);
      });
      console.log("sig data: ", data);
      // then pass this into the process data function which will combine it with abundnace data
      this.processData(data);
    },
    processData(rawData) {
      // only keep records that fall under significane threshold
      this.currentDataset = rawData.filter(
        (d) => d.q <= this.localSigThreshold
      );
      //console.log('check: ', rawData.filter((d) => d.q <= 0.1))
      console.log(this.localSigThreshold);
      console.log(this.currentDataset);
      // index has long concatenated taxa strings
      // loop thru and split these into different key/obj pairs
      this.currentDataset.forEach((d) => {
        let obj = {};
        let name, val;

        d[""].split(";").forEach((j) => {
          name = "taxa_" + j.split("__")[0];
          val = j.split("__")[1];

          d[name] = val;
        });
        d.longname = d[""];
        d.value = +d.q;

        // for this genus, find the abundance Median for each DiseaseState and store it
        d.abundance = this.groupedAbundanceData.find(
          (a) => a.name == d.taxa_d
        ).mediansByDisease;

        // for this genus, find the abundance Median for each DiseaseState and store it
        d.zeroRatio = this.groupedAbundanceData.find(
          (a) => a.name == d.taxa_d
        ).ratioOfZeroValues;
      });

      this.currentDataset.sort((a, b) => {
        return a.value - b.value;
      });

    },
    drawViz() {
      const margin = {
        left: 120,
        right: 70,
        top: 10,
        bottom: 40,
      };

      const width = document.querySelector("#circle-plot-parent").clientWidth;
      console.log(width);
      const height = 600;

      const svg = d3
        .select("#circle-plot-container")
        .append("svg")
        .attr("width", width)
        .attr("height", height + margin.bottom); // adding margin after to preserve the true value of height, so i can height/2

      const xScale = d3
        .scaleLinear()
        // .domain(d3.extent(this.currentDataset, (d) => d.value).reverse()) // reverse axis so small values on right
        .domain([this.localSigThreshold, 0])
        .range([margin.left, width - margin.right]);

      let rowOne = height / 2 / 2;
      let rowTwo = height / 2 + rowOne;
      let currentRow;

      var yScale = d3
        .scaleBand()
        .domain(this.selectedDiseaseStates)
        .range([0, height + margin.bottom]);

      // loop through the abundance data to find the maximum median value
      let vals = [];
      let ratios = [];
      this.selectedDiseaseStates.forEach((d, index) => {
        this.currentDataset.forEach((d, i) => {
          vals.push(Object.values(d.abundance[index])[0]);
          ratios.push(Object.values(d.zeroRatio[index])[0]);
        });
      });

      // remove NaNs
      let filtVals = vals.filter(function (value) {
        return !Number.isNaN(value);
      });

      const rScale = d3
        .scaleSqrt()
        .domain([0, Math.max(...filtVals)])
        .range([0, 40]);
      console.log(Math.max(...ratios));
      const oScale = d3
        .scaleLinear()
        .domain([0, Math.max(...ratios)])
        .range([0, 1]);

      // color legend

      //Append a defs (for definition) element to your SVG
      var defs = svg.append("defs");

      //Append a linearGradient element to the defs and give it a unique id
      var linearGradient = defs
        .append("linearGradient")
        .attr("id", "linear-gradient");

      //Set the color for the start (0%)
      linearGradient
        .append("stop")
        .attr("offset", "0%")
        .attr("stop-color", "#ffffff"); //white

      //Set the color for the end (100%)
      linearGradient
        .append("stop")
        .attr("offset", "100%")
        .attr("stop-color", "#000000"); //dark blue

      //Draw the rectangle and fill with gradient
      svg
        .append("rect")
        .attr("width", 180)
        .attr("height", 12)
        .attr("x", width - 200)
        .attr("y", 30)
        .attr("stroke", "gray")
        .attr("stroke-width", 1)
        .style("fill", "url(#linear-gradient)");

      svg
        .append("text")
        .attr("x", width - 200)
        .attr("y", 20)
        .attr("font-size", 12)
        .text("Hue = presence of non-zero values");

      // x-axis
      svg
        .append("g")
        .attr("transform", `translate(0, ${height / 2})`)
        .call(d3.axisBottom(xScale));

      // Add X axis label:
      svg
        .append("text")
        .attr("text-anchor", "end")
        .attr("x", width / 2 + margin.left)
        .attr("y", height / 2 - 10)
        .attr("font-size", 12)
        .attr("fill", "#757575")
        .text("significance (false discovery rate Q)");

      svg
        .append("text")
        .attr("text-anchor", "end")
        .attr("x", width / 2 - 18)
        .attr("y", margin.top + 98)
        .attr("font-size", 12)
        .attr("text-anchor", "end")
        .attr("fill", "#111111")
        .text("more significant");

      svg
        .append("line")
        .attr("y1", margin.top + 95)
        .attr("y2", margin.top + 95)
        .attr("x1", width / 2 - 10)
        .attr("x2", width / 2 + 20)
        .attr("stroke", "#111111")
        .attr("stroke-width", 1);

      svg
        .append("polygon")
        .attr(
          "points",
          `${width / 2 + 28},${margin.top + 95},
          ${width / 2 + 20},${margin.top + 92},
          ${width / 2 + 20},${margin.top + 98}`
        )
        .attr("fill", "#111111");

      this.selectedDiseaseStates.forEach((disease, index) => {
        let jitter = 20;
        let jitterCounter = 1;
        if (index == 0) {
          currentRow = rowOne;
        } else {
          currentRow = rowTwo;
        }

        // left axis labels
        svg
          .append("text")
          .attr("x", margin.left / 3)
          .attr("y", currentRow - 60)
          .text("Pop " + (Number(index) + 1));

        console.log(disease);
        if (disease.length > 1) {
          disease.forEach((d, i) => {
            svg
              .append("rect")
              .attr("x", margin.left / 3)
              .attr("y", currentRow - 38 + i * 30)
              .attr("rx", 5)
              .attr("width", 60)
              .attr("height", 25)
              .attr("fill", () => {
                if (index == 0) {
                  return "#f6a704";
                } else {
                  return "#00b5e0";
                }
              });

            svg
              .append("text")
              .attr("x", margin.left / 3 + 30)
              .attr("y", currentRow - 20 + i * 30)
              .attr("fill", "white")
              .attr("text-anchor", "middle")
              .text(d);
          });
        } else {
          svg
            .append("rect")
            .attr("x", margin.left / 3)
            .attr("y", currentRow - 38)
            .attr("rx", 5)
            .attr("width", 60)
            .attr("height", 25)
            .attr("fill", () => {
              if (index == 0) {
                return "#f6a704";
              } else {
                return "#00b5e0";
              }
            });

          svg
            .append("text")
            .attr("x", margin.left / 3 + 30)
            .attr("y", currentRow - 20)
            .attr("fill", "white")
            .attr("text-anchor", "middle")
            .text(disease);
        }

        let maxHeight = 0;

        const layerBehindDots = svg
          .append("g")
          .attr("width", width)
          .attr("height", height + margin.bottom); // adding margin after to preserve the true value of height, so i can height/2

        var node = svg
          .append("g")
          .selectAll("circle")
          .data(this.currentDataset)
          .join("circle")
          .attr("class", "node")
          .attr("r", 5)
          .attr("cx", width/2)
          .attr("cy", height/2)
          .attr("r", (d) => {
            let val = Object.values(d.abundance[index]);
            return rScale(val[0]);
          })
          .attr("abundanceVal", (d) => {
            let val = Object.values(d.abundance[index]);
            return val[0];
          })
          .attr("qVal", (d) => {
            return d.value;
          })
          .attr("fullname", (d) => {
            return d.longname;
          })
          .attr("fill", () => {
            if (index == 0) {
              return "#f6a704";
            } else {
              return "#00b5e0";
            }
          })
          .attr("fill-opacity", (d) => {
            let val = Object.values(d.zeroRatio[index]);
            return oScale(val[0]);
          })
          .attr("stroke", "black")
          .on("mouseover", function (event) {
            let thisNode = d3.select(this);

            const nameForThisDot = thisNode.attr("fullname");
            const dots = svg.selectAll(
              "circle[fullname='" + nameForThisDot + "']"
            );

            // pull out the abundance for BOTH dots on top and bottom row
            let topBugVal, bottomBugVal;
            let topRatioVal, bottomRatioVal;
            dots._groups.forEach((d) => {
              
              d.forEach((j, i) => {
                if (i == 0) {
                  topBugVal =
                    (parseFloat(j.attributes.abundanceVal.value) * 100).toFixed(
                      2
                    ) + "%";

                  topRatioVal =
                    oScale
                      .invert(
                        parseFloat(j.attributes["fill-opacity"].value) * 100
                      )
                      .toFixed(2) + "%";
                } else {
                  bottomBugVal =
                    (parseFloat(j.attributes.abundanceVal.value) * 100).toFixed(
                      2
                    ) + "%";
                  bottomRatioVal =
                    oScale
                      .invert(
                        parseFloat(j.attributes["fill-opacity"].value) * 100
                      )
                      .toFixed(2) + "%";
                }
              });
            });

            layerBehindDots
              .append("line")
              .attr("x1", dots._groups[0][0].cx.animVal.value)
              .attr(
                "y1",
                dots._groups[0][0].cy.animVal.value +
                  dots._groups[0][0].r.baseVal.value
              )
              .attr("x2", dots._groups[0][1].cx.animVal.value)
              .attr(
                "y2",
                dots._groups[0][1].cy.animVal.value -
                  dots._groups[0][1].r.baseVal.value
              )
              .attr("id", "connector-line")
              .attr("stroke", "black")
              .attr("stroke-width", 2);

            const splitName = nameForThisDot.split(";");
            // var arraycontainsturtles = (myarr.indexOf("turtles") > -1);

            let genusName, denovoName;
            splitName.forEach((d) => {
              if (d.includes("g__")) {
                let name = d;
                if (d.length > 24) {
                  // truncate string to length 20
                  name = d.substring(0, 18) + "...";
                }

                if (d.length == 0) {
                  name = "Unknown"
                }
                genusName = name;
              }

              if (d.includes("d__")) {
                denovoName = d;
              }
            });

            dots.attr("stroke-width", 3);

            const svgTooltipWidth = 320;
            let translateX;
            let translateY;

            // prevent tip from going off the right side of screen
            if (
              document
                .getElementById("circle-plot-container")
                .getBoundingClientRect().width -
                svgTooltipWidth <
              thisNode.attr("cx")
            ) {
              console.log("switch to left side");
              translateX = thisNode.attr("cx") - svgTooltipWidth - 30;
              translateY = thisNode.attr("cy");
            } else {
              translateX = Number(thisNode.attr("cx")) + 30;
              translateY = thisNode.attr("cy");
            }

            const box = svg
              .append("g")
              .attr("id", "svg-tooltip")
              .attr(
                "transform",
                "translate(" + translateX + ", " + translateY + ")"
              );

            box
              .append("rect")
              .attr("width", svgTooltipWidth)
              .attr("height", 160)
              .attr("x", 10)
              .attr("y", 0)
              .attr("rx", 5)
              .attr("ry", 5)
              .attr("fill", "#ffffff")
              .attr("stroke-width", 1)
              .attr("stroke", "#000000");

            box
              .append("line")
              .attr("x1", 20)
              .attr("y1", 60)
              .attr("x2", svgTooltipWidth - 10)
              .attr("y2", 60)
              .attr("stroke", "black")
              .attr("stroke-width", 1);

            let bugTitle;
            if (genusName.substring(3) == "") {
              bugTitle = "Genus SPP";
            } else {
              bugTitle = genusName.substring(3);
            }

            box
              .append("text")
              .attr("x", 20)
              .attr("y", 25)
              .attr("font-size", 20)
              .attr("font-weight", "bold")
              .text(bugTitle);

            box
              .append("text")
              .attr("x", svgTooltipWidth - 80)
              .attr("y", 50)
              .attr("font-size", 14)
              .text("Q: " + Number(thisNode.attr("qVal")).toFixed(3));

            box
              .append("text")
              .attr("x", 20)
              .attr("y", 50)
              .attr("font-size", 14)
              .text("ID: " + denovoName.substring(3));

            box
              .append("text")
              .attr("x", svgTooltipWidth * 0.4)
              .attr("y", 78)
              .attr("text-anchor", "end")
              .attr("font-size", 12)
              .text("ABUNDANCE");

            box
              .append("text")
              .attr("x", svgTooltipWidth * 0.4)
              .attr("y", 90)
              .attr("font-size", 12)
              .attr("text-anchor", "end")
              .text("(median)");

            box
              .append("text")
              .attr("x", svgTooltipWidth * 0.9)
              .attr("y", 78)
              .attr("font-size", 12)
              .attr("text-anchor", "end")
              .text("PRESENCE");

            box
              .append("text")
              .attr("x", svgTooltipWidth * 0.9)
              .attr("y", 90)
              .attr("font-size", 12)
              .attr("text-anchor", "end")
              .text("(non-zero values)");

            box
              .append("text")
              .attr("x", svgTooltipWidth * 0.9)
              .attr("y", 115)
              .attr("fill", "#f6a704")
              .attr("font-weight", 600)
              .attr("text-anchor", "end")
              .text(topRatioVal);

            box
              .append("text")
              .attr("x", svgTooltipWidth * 0.9)
              .attr("y", 140)
              .attr("fill", "#00b5e0")
              .attr("font-weight", 600)
              .attr("text-anchor", "end")
              .text(bottomRatioVal);

            box
              .append("text")
              .attr("x", svgTooltipWidth * 0.4)
              .attr("y", 115)
              .attr("text-anchor", "end")
              .attr("fill", "#f6a704")
              .attr("font-weight", 600)
              .text(topBugVal);

            box
              .append("text")
              .attr("x", svgTooltipWidth * 0.4)
              .attr("y", 140)
              .attr("text-anchor", "end")
              .attr("font-weight", 600)
              .attr("fill", "#00b5e0")
              .text(bottomBugVal);

            svg
              .append("line")
              .attr("x1", thisNode.attr("cx"))
              .attr("y1", thisNode.attr("cy"))
              .attr("x2", thisNode.attr("cx"))
              .attr("y2", thisNode.attr("cy"))
              .attr("stroke", "black")
              .attr("stroke-width", 3)
              .attr("id", "tip-line");

            // svg.selectAll("#connector-"+nameForThisDot).attr('stroke-width', 3)
          })
          .on("mouseout", function (event) {
            svg.selectAll("circle").attr("stroke-width", 1);
            svg.select("#connector-line").remove();

            d3.select("#svg-tooltip").remove();

            d3.select("#tip-line").remove();
          });

        // GROUPED SIMULATION
        var simulation = d3
          .forceSimulation(this.currentDataset)
          .force(
            "x",
            d3
              .forceX()
              .x(function (d) {
                return xScale(d.value);
              })
              .strength(0.05)
          )
          .force(
            "y",
            d3
              .forceY()
              .y(function (d) {
                return yScale(disease);
              })
              .strength(0.02)
          )
          // .force('y', d3.forceY().y(function (d) {
          //     return height / 2;
          // }).strength(0.8))
          .force(
            "collision",
            d3
              .forceCollide()
              .radius(function (d) {
                // prevent circle overlap when collide
                let val = Object.values(d.abundance[index]);
                return rScale(val[0]);
              })
              .strength(0.5)
          )
          .force("charge", d3.forceManyBody().strength(1.1)); // send nodes away from eachother

        simulation.nodes(this.currentDataset).on("tick", function (d) {
          node
            .attr("cx", (d) => d.x)
            .attr("cy", (d) => {
              if (index == 0) {
                return d.y;
              } else {
                return d.y + rowOne + 150;
              }
            });
        });
      });
    },
    removeElementsWithValue(arr, val) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === val) {
          arr.splice(i, 1);
        }
      }
      return arr;
    },
    calculateMedianNoZeros(arr) {
      let filteredArr = this.removeElementsWithValue(arr, 0);

      filteredArr.sort(function (a, b) {
        return a - b;
      });
      var half = Math.floor(filteredArr.length / 2);

      if (filteredArr.length % 2) return filteredArr[half];
      else return (filteredArr[half - 1] + filteredArr[half]) / 2.0;
    },
    groupAbundanceData() {
      // make new arr for objects
      let groupedData = [];
      let allMediansByDiseaseState = [];

      let allBugVals = [];

      // NOTE: hardcoded to group by genus, which seems to be the lowest common taxa which has a readable name (unlinke denovo)
      const taxaToViz = "taxa_d";

      // group data and loop thru each grouped entry
      d3.group(this.abundanceData, (d) => d[taxaToViz]).forEach((d) => {
        let obj = { values: {} };

        // store the name (key) of this grouped object as the bacteria name for later
        obj.name = d[0][taxaToViz];

        // for this bug, loop thru every unique patient ID, which are keys inside of the d iterator
        this.patients.forEach((patientId) => {
          // NOTE: "patient" may be relative to the metadata, check cols to make sure consistent across studies
          let patientMetadata = this.metadata.find(
            (d) => d.patient === patientId
          );

          // arr for storing values for each patient
          let patientTotalForBug = [];

          let total = 0;
          // loop thru the original d iterator, which is an arr with a key matching the bug name,
          d.forEach((j) => {
            // for each arr, only store the total for THIS patient (k iterator)
            // patientTotalForBug.push(+j[patientId]);
            total += +j[patientId];
          });

          obj.values[patientId] = total;

          this.patientDiseaseStates[patientId] = patientMetadata.DiseaseState;

          allBugVals.push(total);
        });
        groupedData.push(obj);
      });
      // console.log(Math.max(...allBugVals))

      this.selectedDiseaseStates = [this.popOne, this.popTwo];

      // convert patientdDisease Object to array of 2d arrays
      let patientDiseaseStatesAsArrary = Object.entries(
        this.patientDiseaseStates
      );

      // calculate the median per disease state
      // for every bacteria after it has been "grouped" into a taxa level...
      groupedData.forEach((b) => {
        // init an empty arr to store each disease state median
        b.mediansByDisease = [];
        b.ratioOfZeroValues = [];

        // for each disease state d...
        this.selectedDiseaseStates.forEach((d) => {
          let disease, group;
          if (d.length < 2) {
            disease = d[0];
            // filter to one disease group at a time
            group = patientDiseaseStatesAsArrary.filter(
              ([key, value]) => value === disease
            );
          } else if (d.length == 2) {
            disease = d[0] + "_" + d[1];
            // filter to one disease group at a time
            group = patientDiseaseStatesAsArrary.filter(
              ([key, value]) => value === d[0] || value === d[1]
            );
          } else {
            // assume max of three disease states in a single population
            disease = d[0] + "_" + d[1] + "_" + d[3];
            // filter to one disease group at a time
            group = patientDiseaseStatesAsArrary.filter(
              ([key, value]) =>
                value === d[0] || value === d[1] || value === d[2]
            );
          }

          let entryOne = {};
          let entryTwo = {};

          let arrOfVals = [];
          group.forEach((j) => {
            arrOfVals.push(b.values[j[0]]);
          });

          let ratio = arrOfVals.filter((v) => v != 0).length / arrOfVals.length;
          entryOne[disease] = ratio;
          b.ratioOfZeroValues.push(entryOne);

          let medianVal = this.calculateMedianNoZeros(arrOfVals);
          // console.log(this.removeElementsWithValue(arrOfVals, 0).length)
          entryTwo[disease] = medianVal;
          b.mediansByDisease.push(entryTwo);

          allMediansByDiseaseState.push(medianVal);
        });
      });
      console.log("data: ", groupedData);

      this.groupedAbundanceData = groupedData;
      this.maxMedianBugVal = allMediansByDiseaseState
        .filter((item) => !isNaN(item))
        .reduce((a, b) => {
          return Math.max(a, b);
        });

      // console.log(this.maxMedianBugVal);
    },
  },
};
</script>

<style scoped>
.flex-div {
  display: flex;
}

.flex-1-fixed {
  width: 60px;
}

.flex-2 {
  flex-grow: 2;
  flex-basis: 30%;
  margin-bottom: 10px;
}

.orange-stat {
  font-size: 22px;
  color: #ffb300;
  font-weight: 600;
}

.definition-panel p {
  font-size: 16px;
}

.bubble-legend-item {
  display: inline-block;
  margin-left: 2px;
  border: 1px solid gray;
  border-radius: 20px;
  margin-right: 5px;
  margin-bottom: 4px;
}

#alert-text {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 80px;
  display: none;
}

#mobile-alert-text {
  text-align: center;
  margin-bottom: 50px;
  max-width: 600px;
  display: none;
  margin-left: 0px
}


#mobile-alert-text p {
  margin-left: 0px
}
</style>