import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import * as d3 from "d3";
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-TYCQFDK3C8",
  params: {
    send_page_view: true
  } }
});

Vue.config.productionTip = false


new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
