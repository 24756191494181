<template>
  <div style="overflow: hidden; width: 100%">
    <div v-if="!mobileListener" id="select-to-sort-text">↓ Select a row to sort by a bacterium</div>
    <div id="bubble-chart" :class="constrainedView ? '' : 'scrolls'"></div>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "BubbleMatrix",
  props: {
    taxa: {
      required: true,
      type: String,
    },
    patients: {
      required: true,
      type: Array,
    },
    currentDataset: {
      required: true,
      type: Array,
    },
    currentMetadata: {
      required: true,
      type: Array,
    },
    dataset: {
      required: false,
      type: String,
    },
    parentResized: {
      required: false,
      type: Number,
    },
    transposedListener: {
      required: false,
      type: Boolean,
    },
    transposedData: {
      // optional, but needed for the univariate testing demo
      required: false,
      type: Array,
    },
    colorPalette: {
      required: false,
      type: Object,
    },
    constrainedView: {
      required: false,
      type: Boolean,
      default: true,
    },
    groupSortByDisease: {
      required: true,
      type: Boolean,
      default: true,
    },
    mobileListener: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      useMedianValues: true,
      userHasSorted: false,
      selectedBacteriaForSorting: null,
      sortedPatients: [],
      patientDiseaseStates: {}, // keys are patient IDs, values are disease state strings
    };
  },
  mounted() {
    // only draw viz if the SVG does not already exists
    // prevents edge case where matrix is drawn twice
    setTimeout(() => {
      if (document.getElementById("bubble-chart-svg") == null) {
        this.drawViz("taxa_" + this.taxa, this.transposedListener);
      } else {
        // console.log("chart drawn already!");
      }
    }, 100);
  },
  watch: {
    groupSortByDisease() {

      if (this.userHasSorted) {
        if (this.groupSortByDisease == true) {
          this.sortOnBacteriaClickWithinDiseaseState(
            this.selectedBacteriaForSorting.values
          );
        } else {
          this.sortOnBacteriaClick(this.selectedBacteriaForSorting.values);
        }

        this.drawViz(
          "taxa_" + this.taxa,
          this.transposedListener,
          "bacteriaName"
        );
      }
    },
    constrainedView() {
      this.drawViz("taxa_" + this.taxa, this.transposedListener);
    },
    dataset() {

      setTimeout(() => {
        this.drawViz("taxa_" + this.taxa);
      }, 200);
    },
    taxa() {
      this.drawViz("taxa_" + this.taxa, this.transposedListener);
    },
    parentResized() {
      this.drawViz(
        "taxa_" + this.taxa,
        this.transposedListener,
        "bacteriaName",
        this.mobileListener
      );
    },
    transposedListener() {
      this.drawViz("taxa_" + this.taxa, this.transposedListener);
    },
  },
  methods: {
    onBacteriaClick(event) {
      this.$emit("bacteriaClicked", event);
    },
    onMediansCalculated(event) {
      this.$emit("groupedMedianDataSender", event);
    },
    calculateMedianNoZeros(arr) {
      function removeElementsWithValue(arr2, val) {
        var i = arr2.length;
        while (i--) {
          if (arr2[i] === val) {
            arr2.splice(i, 1);
          }
        }
        return arr2;
      }

      let filteredArr = removeElementsWithValue(arr, 0);
      filteredArr.sort(function (a, b) {
        return a - b;
      });
      var half = Math.floor(filteredArr.length / 2);

      if (filteredArr.length % 2) return filteredArr[half];
      else return (filteredArr[half - 1] + filteredArr[half]) / 2.0;
    },
    sortByDiseaseState() {
      // will dynamically assign sort order based on disease states for each study
      let autoSortOrder = {};
      Object.keys(this.colorPalette).forEach((d, i) => {
        autoSortOrder[d] = i;
      });

      // sort order of patients
      this.currentMetadata.sort(function (a, b) {
        return autoSortOrder[a.DiseaseState] - autoSortOrder[b.DiseaseState];
      });

      this.sortedPatients = this.currentMetadata.map((d) => d.patient);
    },
    sortOnBacteriaClick(bacteriaObject) {
      const allIds = Object.keys(bacteriaObject);

      allIds.sort((a, b) => {
        let aVal = bacteriaObject[a];
        let bVal = bacteriaObject[b];

        return bVal - aVal;
      });

      this.sortedPatients = allIds;

      this.drawViz(
        "taxa_" + this.taxa,
        this.transposedListener,
        "bacteriaName"
      );
    },
    sortOnBacteriaClickWithinDiseaseState(bacteriaObject) {
      
      // const allIds = Object.keys(bacteriaObject);

      // convert Object to Array of 2 value Arrays
      const allIdsObjectAsArray = Object.entries(bacteriaObject);

      let patientIdListSorted = [];
      // for each disease state d ...
      Object.keys(this.colorPalette).forEach((d, i) => {
        // make empty arr that will be filled each loop
        let patientIdList = [];

        // grab only data with this disease state
        let group = this.currentMetadata.filter((j) => j.DiseaseState === d);

        // loop thru and store all patient ids in a list
        group.forEach((entry) => {
          patientIdList.push(entry["sample"]); // NOTE: THIS IS SPECIFIC TO ALM STUDY, NEED THE SAME SAMPLE COLUMN NAME FOR ALL STUDIES
        });

        // filter the arr based on the current patient Id list
        let filteredIds = allIdsObjectAsArray.filter(([key, value]) =>
          patientIdList.includes(key)
        );

        // convert these filtered IDs back into an Object
        let filteredIdsAsObject = Object.fromEntries(filteredIds);

        // then only store the keys from this object
        let onlyPatientKeys = Object.keys(filteredIdsAsObject);

        // now take that list and sort it based on the values of the original bacteriaObject passed into the method
        onlyPatientKeys.sort((a, b) => {
          let aVal = bacteriaObject[a];
          let bVal = bacteriaObject[b];

          return bVal - aVal;
        });

        // now loop thru the values of this arr, in numerically sorted order for only this disease state,
        // and push it into the final list
        onlyPatientKeys.forEach((p) => {
          patientIdListSorted.push(p);
        });
      });

      this.sortedPatients = patientIdListSorted;

      this.drawViz(
        "taxa_" + this.taxa,
        this.transposedListener,
        "bacteriaName"
      );
    },
    drawViz(
      taxaToViz,
      transposedState,
      sortMethod = "diseaseState",
      mobile = this.mobileListener
    ) {
      // if the svg or canvas alraeady exist then remove them
      d3.select("svg").remove();
      d3.select("canvas").remove()

      if (sortMethod == "diseaseState") {
        this.sortByDiseaseState();
      }

      // make new arr for objects
      let groupedData = [];

      let allBugVals = [];
      let allMediansByDiseaseState = [];

      // group data and loop thru each grouped entry
      // NOTE: could I use this in Vuex? also using a version of it in CirclePlot
      d3.group(this.currentDataset, (d) => d[taxaToViz]).forEach((d) => {
        let obj = { values: {} };

        // store the name (key) of this grouped object as the bacteria name for later
        obj.name = d[0][taxaToViz];

        // for this bug, loop thru every unique patient ID, which are keys inside of the d iterator
        this.patients.forEach((patientId) => {
          // NOTE: "patient" may be relative to the metadata, check cols to make sure consistent across studies
          let patientMetadata = this.currentMetadata.find(
            (d) => d.patient === patientId
          );

          // arr for storing values for each patient
          let patientTotalForBug = [];

          let total = 0;
          // loop thru the original d iterator, which is an arr with a key matching the bug name,
          d.forEach((j) => {
            // for each arr, only store the total for THIS patient (k iterator)
            // patientTotalForBug.push(+j[patientId]);
            total += +j[patientId];
          });

          obj.values[patientId] = total;
          
          this.patientDiseaseStates[patientId] = patientMetadata.DiseaseState;

          allBugVals.push(total);
        });
        // store the median for each bacteria (am I using this in the UI?)
        obj.median = this.calculateMedianNoZeros(Object.values(obj.values));

        // push to arr
        groupedData.push(obj);
      });

      // calculate the median per disease state
      // for every bacteria after it has been "grouped" into a taxa level...
      groupedData.forEach((b) => {
        // init an empty arr to store each disease state median
        b.mediansByDisease = [];

        // for each disease state d...
        Object.keys(this.colorPalette).forEach((d) => {
          // convert patientdDisease Object to array of 2d arrays
          let patientDiseaseStatesAsArrary = Object.entries(
            this.patientDiseaseStates
          );

          // filter to one disease group at a time
          let group = patientDiseaseStatesAsArrary.filter(
            ([key, value]) => value === d
          );

          let entry = {};
          let arrOfVals = [];
          group.forEach((j) => {
            arrOfVals.push(b.values[j[0]]);
          });

          let medianVal = this.calculateMedianNoZeros(arrOfVals);
          entry[d] = medianVal;
          b.mediansByDisease.push(entry);

          allMediansByDiseaseState.push(medianVal);
        });
      });
      this.onMediansCalculated(groupedData);

      // max val to use for desktop scale, uses max summed value across all patients
      const maxBugVal = allBugVals.reduce((a, b) => {
        return Math.max(a, b);
      });

      // use for medians PER DISEASE STATE
      const maxMedianBugVal = allMediansByDiseaseState
        .filter((item) => !isNaN(item))
        .reduce((a, b) => {
          return Math.max(a, b);
        });

      // use for median across all patients
      // const maxMedianBugVal = Math.max(...groupedData.map(o => o.median))

      /*
    BEGIN BY DEFINING THE DIMENSIONS OF THE SVG and CREATING THE SVG CANVAS
    */
      const rootDiv = d3.select("#bubble-chart");

      const svg = rootDiv.append("svg").attr("id", "bubble-chart-svg");

      let verticalRowSpacing = 20;

      let rScale = d3
        .scaleSqrt()
        .domain([0, maxBugVal])
        .range([0.5, verticalRowSpacing / 1.8]);

      // NOTE: Mobile version
      if (mobile) {
        let width = document.querySelector("#bubble-chart").clientWidth;
        const margin = {
          top: 80,
          bottom: 50,
          left: 160,
          right: 20,
        };

        verticalRowSpacing = 30;
        rScale
          .domain([0, maxMedianBugVal])
          .range([1.5, verticalRowSpacing / 1.2]);

        // set svg height based on the number of rows plus the margin
        svg
          .attr(
            "height",
            groupedData.length * verticalRowSpacing + margin.top + margin.bottom
          )
          .attr("width", width);

          let height =
          groupedData.length * verticalRowSpacing + margin.top + margin.bottom;

        //  set root div same dimensions
        d3.select('#bubble-chart').style("height", height + "px");

        groupedData.forEach((d, a) => {
          const xScale = d3
            .scaleLinear()
            .domain([0, Object.keys(this.colorPalette).length])
            .range([margin.left, width - margin.right]);

          const yScale = d3
          .scaleLinear()
          .domain([0, groupedData.length])
          .range([margin.top, height]);

          const topLabels = svg
            .append("g")
            .attr("x", margin.left)
            .attr("y", margin.top);

          

          Object.keys(this.colorPalette).forEach((k, i) => {
            // add sample ID labels, only do one time
            if (a == 0) {
              
              topLabels
                .append("text")
                .attr("x", -5)
                .attr("y", 3)
                .attr("transform", () => {
                  return (
                    "translate( " +
                    xScale(i) +
                    " , " +
                    (margin.top - 30) +
                    ")," +
                    "rotate(-45)"
                  );
                })
                .attr("font-size", 12)
                .text(k);
            }

            // add bacteria name labels for this group. only do this one time.
            if (i == 0) {
              svg
                .append("text")
                .attr("x", margin.left - 35)
                .attr("y", yScale(a)+ 4)
                .text(d.name) // grab the name
                // .attr("allValues", j.values)
                .attr("font-size", 12)
                .attr("cursor", "pointer")
                .attr("text-anchor", "end");
            }

            // draw the circles
            svg
              .append("circle")
              .attr("cx", xScale(i))
              .attr("cy", yScale(a))
              .attr("r", rScale(d.mediansByDisease[i][k]))
              .attr("opacity", 0.8)
              .attr("fill", () => {
                return this.colorPalette[k];
              })
              .attr("bugName", d.name)
              .attr("bugVal", d.mediansByDisease[i][k])
              .attr("class", "bubble-row-"+a)
              // .attr("patientId", d)
              .attr("stroke", "black")
              .attr("stroke-width", 0)
              .on("click", (e) => {
                d3.selectAll("circle").attr("stroke-width", 0);
                d3.selectAll('.bubble-labels').attr('opacity', 0)
                e.target.setAttribute("stroke-width", 2);
                let rowNum = Math.round(yScale.invert(+e.target.attributes.cy.value));
                
                d3.selectAll('.bubble-row-'+rowNum).attr("stroke-width", 3);

                d3.selectAll('.bubble-text-'+rowNum).attr('opacity', 0.8)

                d3.selectAll('.bubble-rect-'+rowNum).attr('opacity', 0.9)
              });

              svg
                .append('rect')
                .attr('x',xScale(i)-24)
                .attr('y',yScale(a)-52)
                .attr('width', 44)
                .attr('height', 45)
                .attr('fill', 'white')
                .attr('opacity', 0)
                .attr("class", "bubble-labels bubble-rect-"+a)
                .attr('pointer-events', 'none')

              svg
                .append("text")
                .attr("x", xScale(i))
                .attr("y", yScale(a)-15)
                .attr('opacity', 0)
                .attr('text-anchor', 'middle')
                .attr('pointer-events', 'none')
                .attr("class", "bubble-labels bubble-text-"+a)
                .attr('font-size', 15)
                .attr('font-weight', 600)
                .text( function(){
                  let val = +d.mediansByDisease[i][k];
                  
                  if (!val) {
                    return "0"
                  } else {
                    return (val*100).toFixed(1)
                  }
              })

              // draw the label for the tooltip
              // but only one time, and only on the final loop through the forEach loop
              if (i == Object.keys(this.colorPalette).length-1) {
                svg.append('text')
                .attr('x', xScale.range()[0]+70)
                .attr('y', yScale(a)-38)
                .attr('opacity', 0)
                .attr('text-anchor', 'middle')
                .attr('pointer-events', 'none')
                .attr("class", "bubble-labels bubble-text-"+a)
                .attr('font-size', 12)
                .attr('font-weight', 600)
                .text('median abundance (%)') 
              }
          });
        });

        // NOTE: Desktop version
      } else {
        // DESKTOP VERSION IN THESE BRACKETS

      const canvas = rootDiv.append("canvas").attr("id", "bubble-chart-canvas");

      const context = canvas.node().getContext("2d");

        // MARGIN FOR DESKTOP VERSION
        const margin = {
          top: 80,
          bottom: 50,
          left: 140,
          right: 10,
        };

        let height =
          groupedData.length * verticalRowSpacing + margin.top + margin.bottom;

          let width;
        if (this.constrainedView) {
          width = document.querySelector("#bubble-chart").clientWidth;
          
        } else {
          width = this.sortedPatients.length * verticalRowSpacing + margin.left + margin.right;
        }
        
        // set svg height based on the number of rows plus the margin
        svg.attr("height", height);
        svg.attr("width", width);
        
        //  set root div same dimensions
        rootDiv.style("height", height + "px");

        // set canvas height same as svg
        canvas.attr("height", height).attr("width", width);

        // get current size of the canvas
        let rect = document.querySelector("#bubble-chart-canvas").getBoundingClientRect();

        // increase the actual size of our canvas
        canvas.attr("width", rect.width * devicePixelRatio);
        canvas.attr("height", rect.height * devicePixelRatio);

        // this line makes everything bigger, and then the CSS resizes it back down to correct size
        context.scale(devicePixelRatio, devicePixelRatio);

        // scale everything down using CSS
        d3.select("#bubble-chart-canvas").style("width", rect.width+"px")
        document.getElementById("bubble-chart-canvas").style.height = rect.height + "px"

        const xScale = d3
          .scaleLinear()
          .domain([0, this.sortedPatients.length])
          .range([margin.left, width - margin.right]);

        const yScale = d3
          .scaleLinear()
          .domain([0, groupedData.length])
          .range([margin.top, height]);

        const topLabels = svg
          .append("g")
          .attr("x", margin.left)
          .attr("y", margin.top);

        // on any mouseover event, trigger the following code
        d3.select("#bubble-chart-svg").on("mousemove", (event) => {
          // remove any existing hover circles
          d3.select("#circle-outline-on-hover").remove();
          d3.select("#svg-tooltip").remove();
          d3.select("#crosshair-x-highlight").remove();
          d3.select("#crosshair-y-highlight").remove();

          // track the mouse movements
          let mouse = d3.pointer(event);

          // pass the mouse movement through inverted scales in order to locate the index value of each
          // we will use this index value for x and y scale to find the corresponding data point in the matrix
          let indexes = [
            Math.abs(Math.round(xScale.invert(mouse[0]))),
            Math.abs(Math.round(yScale.invert(mouse[1]))),
          ];

          // check to make sure mouse is on chart, not on axis labels
          if (mouse[0] > margin.left-2 && mouse[1] > margin.top-2 && mouse[0] < (width-margin.right-7)) {
          // add a circle using the indexes above to match the x and y location in the matrix
          svg
            .append("circle")
            .attr("cx", xScale(indexes[0]))
            .attr("cy", yScale(indexes[1]))
            .attr("fill", "none")
            .attr("id", "circle-outline-on-hover")
            .attr(
              "r", rScale(
                groupedData[indexes[1]].values[this.sortedPatients[indexes[0]]] // this pulls out the single value for the circle
              )
            )
            .attr("stroke", "black")
            .attr("stroke-width", 3);

                  const svgTooltipWidth = 250;
                  const svgTooltipHeight = 100;
                  let translateX;
                  let translateY;

                  // prevent tip from going off the right side of screen
                  if (width - svgTooltipWidth < mouse[0]) {
                    translateX = mouse[0] - svgTooltipWidth - 30
                  } else {
                    translateX = mouse[0]
                  }

                  // prevent tip from going off bottom of chart view
                  if (height - svgTooltipHeight < mouse[1]) {
                    translateY = mouse[1] - svgTooltipHeight;
                  } else {
                    translateY = mouse[1]
                  }

                  const box = svg.append('g')
                    .attr('id', 'svg-tooltip')
                    .attr('transform', 'translate('+translateX+', '+translateY+')');

                  box.append('rect')
                    .attr('width', svgTooltipWidth)
                    .attr('height', svgTooltipHeight)
                    .attr('x', 10)
                    .attr('y', 0)
                    .attr('rx', 5)
                    .attr('ry', 5)
                    .attr('fill', '#ffffff')
                    .attr('stroke-width', 1)
                    .attr('stroke', '#000000');

                  box.append('text')
                    .attr('x', 20)
                    .attr('y', 30)
                    .attr('font-size', 22)
                    .attr('font-weight', 'bold')
                    .text((parseFloat(groupedData[indexes[1]].values[this.sortedPatients[indexes[0]]]) * 100).toFixed(2)+'%');
                  
                  box.append('text')
                    .attr('x', 20)
                    .attr('y', 55)
                    .text("Name: "+groupedData[indexes[1]].name);

                  box.append('text')
                    .attr('x', 20)
                    .attr('y', 80)
                    .text("Sample ID: "+this.sortedPatients[indexes[0]]);

                  svg
                    .append("line")
                    .attr("x1", margin.left)
                    .attr("y1", mouse[1])
                    .attr("x2", mouse[0])
                    .attr("y2", mouse[1])
                    .attr("stroke", "gray")
                    .attr("stroke-width", 1)
                    .attr("id", "crosshair-x-highlight");

                  svg
                    .append("line")
                    .attr("x1", mouse[0])
                    .attr("y1", margin.top - 20)
                    .attr("x2", mouse[0])
                    .attr("y2", mouse[1])
                    .attr("stroke", "gray")
                    .attr("stroke-width", 1)
                    .attr("id", "crosshair-y-highlight");
          }
        });

        // patients columns, bacteria are rows
        groupedData.forEach((j, a) => {
          this.sortedPatients.forEach((d, i) => {
            // add patient id labels at top of viz. only do this one time.
            if (a == 0) {
              topLabels
                .append("text")
                .attr("x", -5)
                .attr("y", 3)
                .attr("transform", () => {
                  return (
                    "translate( " +
                    xScale(i) +
                    " , " +
                    (margin.top - 30) +
                    ")," +
                    "rotate(-50)"
                  );
                })
                .attr("font-size", 6)
                .text(d);
            }

            // add bacteria name labels for this group. only do this one time.
            if (i == 0) {
              svg
                .append("text")
                .attr("x", margin.left - 15)
                .attr("y", yScale(a) + 2)
                .text(j.name) // grab the name
                // .attr("allValues", j.values)
                .attr("font-size", 12)
                .attr("cursor", "pointer")
                .attr("text-anchor", "end")
                .on("click", (event) => {
                  this.userHasSorted = true;
                  if (this.groupSortByDisease) {
                    this.sortOnBacteriaClickWithinDiseaseState(j.values);
                  } else {
                    this.sortOnBacteriaClick(j.values);
                  }

                  // store entire object that was last selected by user
                  // including values
                  this.selectedBacteriaForSorting = j;

                  // send the name back up to App.vue
                  this.onBacteriaClick(j.name);
                });
            }

            // after the labels are drawn, add an axisw title on top with a white rect behind
            // in case of very long ID names
            topLabels.append('rect')
          .attr('x', (width/2)-10)
          .attr('y', margin.top/2-37)
          .attr("width", 80)
          .attr('height', 15)
          .attr("fill", "#ffffff")

        topLabels.append('text')
            .attr('x', width/2)
            .attr('y', margin.top/2-25)
            .attr("font-size", 12)
            .text('Sample IDs')

            // draw circle for each non-zero value
            // else draw line tick
            if (+j.values[d] != 0) {
              context.globalAlpha = 0.85;
              context.beginPath();
              context.fillStyle =
                this.colorPalette[this.patientDiseaseStates[d]];
              const px = xScale(i);
              const py = yScale(a);
              context.arc(px, py, rScale(+j.values[d]), 0, 2 * Math.PI, true);
              context.fill();
            } else {
              context.beginPath();
              context.moveTo(
                xScale(i) - 2,
                yScale(a) + 0
              );
              context.lineTo(
                xScale(i) + 2,
                yScale(a) + 0
              );
              context.lineWidth = 1;
              context.strokeStyle = "gray";
              context.stroke();
            }

            
          });
        });
      
      }
    },
  },
};
</script>

<!-- these styles for elements that D3 appends later on -->
<style>
#bubble-chart-svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2
}
#bubble-chart-canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--  specific to hardcoded elements int he template -->
<style scoped>
.scrolls {
  overflow: scroll;
  max-height: 100vh;
}

#select-to-sort-text {
  font-style: italic;
  font-size: 14px;
}

#bubble-chart {
  position: relative;
  width: 100%;
}
</style>
