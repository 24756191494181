<template>
<div id="loading">
    <div class="row">
        <div>
            <div id="icon-wrapper">
                <v-icon style="position: relative; top: 30%; left: 30%" color="secondary" x-large
                                >mdi-bacteria-outline</v-icon>
            </div>
        </div>
        <div style="flex: 2; padding: 10px; margin-left: 20px">
                <v-progress-linear
                color="teal"
                buffer-value="0"
                indeterminate
                :value="progressVal"
                height="10"
                stream
                ></v-progress-linear>
            <h2 style="margin-top: 20px; margin-bottom: 10px">Hang tight!</h2>
            <p>Your significance analysis is running. Depending on the size of the dataset, this script can take up to a minute to run. In the meantime, learn more about the bacteria in your gut below!</p>
            <hr style="margin-bottom: 30px; margin-top: 20px">
            <h3>{{ bacteriaName[bacteriaName.length - 1]}}</h3>
            <div class="taxa-name">Classification: </div>
            <div class="taxa-name" v-for="(d,i) in bacteriaName" :key="d">
                <span v-if="d != 'Bacteria'"> > </span>
                <span v-if="d.length != 0" :style="[i == d.length ? {'font-weight': '300'} : {'font-weight': '300'}]">{{ d }}</span>
            </div>
            <blockquote>
                {{ bacteriaFact }}
            </blockquote>
            <div v-if="progressVal == 100">
                <v-btn
                :loading="loading3"
                :disabled="loading3"
                color="success"
                class="white--text"
                @click="loader = 'loading3'"
                >
                READY TO VIEW
                <v-icon
                    right
                    dark
                >
                    mdi-check-circle
                </v-icon>
                </v-btn>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {csv} from 'd3-fetch';

export default {
  name: 'LoadingSplash',
  props: {
    progressTracker: {
        required: false,
        type: Number,
        default: 1
    },
    factRefreshCounter: {
        required: false,
        type: Number,
        default: 0
    }
  },
  data() {
    return {
        factDataset: null,
        bacteriaName: [],
        taxa: ["kingdom", "phylum", "class", "order", "family"],
        bacteriaFact: null,
        internalFactRefreshCounter: 0,
    }
  },
  watch: {
    factRefreshCounter(val) {
        if (val != 0) {
            this.internalFactRefreshCounter++;
        this.loadFact();
        }
    },
  },
  computed: {
    progressVal() {
        let val;
        if (this.progressTracker == 1) {
            val = 10;
        } else if (this.progressTracker == 2) {
            val = 60;
        } else if (this.progressTracker == 3) {
            val = 100
        }
        return val;
    }
  },
  mounted() {
    Promise.all([
            csv("../data/bacteria-facts.csv"),
        ]).then((datasets) => {
            this.factDataset = datasets[0];

            this.loadFact();
        });
  },    
  methods: {
    loadFact() {
        // create a random number generator
        const randomNumber = Math.floor(Math.random() * this.factDataset.length);

        this.taxa.forEach((taxa) => {
            if (this.factDataset[randomNumber][taxa] != "") {
                this.bacteriaName.push(this.factDataset[randomNumber][taxa])
            }
        })
        this.bacteriaFact = this.factDataset[randomNumber].text;
    }
  }
}
</script>

<style scoped>
#loading {
    /* background-color: #D4E1E4; */
    background-color: white;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

#icon-wrapper {
    background-color: white; 
    width: 100px; height: 100px; border-radius: 100px
}

h3 {
    font-size: 1.5rem;
}

blockquote {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 300;
    font-style: italic;
}

.row {
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
  margin-top: 10vh;
  padding: 30px;
  border-radius: 5px;
  background-color: #D4E1E4;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)
}

.taxa-name {
    display: inline;
    font-size: 14px;
}

@media screen and (max-width: 700px) {
  .row {
    display: block;
  }

  #icon-wrapper {
    margin: auto;
    margin-bottom: 20px;
  }
}
</style>