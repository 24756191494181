<template>
<div id='mini-bar'>
    <div class="row">
        <div class="bar-container">
            <div v-for="(obj, index) in normalizedVals" :key="index"
                class="bar"
                :class="['val-' + index]"
                :style="'flex-basis:'  + obj.value+'%;' + 'background-color:' + colorPalette[obj.name]">{{obj.originalVal}}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="bar-container">
            <div v-for="(obj, index) in normalizedVals" :key="index"
                :class="['val-' + index]"
                class="bar-label"
                :style="'flex-basis:'  + obj.value+'%;' + 'color:' + colorPalette[obj.name]">{{obj.name}}
            </div>
        </div>
    </div>
</div>
</template>

<script>
// a bar chart built using CSS flexbox
// bars are drawn using normalized values (ie percentages)
// vals on bars are original values passed down to component
// label (key) is appended below bars
export default {
  name: 'MiniStackedBar',
  props: {
    data: {
        required: true,
        type: Object
    },
    colorPalette: {
      required: false,
      type: Object
    }
  },
  computed: {
    normalizedVals() {
        let vals = [];
        let total = [];
        Object.keys(this.data).forEach(key => {
            total.push(this.data[key])
        });

        const sumTotal = total.reduce((a, b) => a + b, 0);

        Object.keys(this.data).forEach(key => {
            
            let obj = {};
            obj.name = key
            obj.value = ((this.data[key] / sumTotal)*100).toFixed(0) 
            obj.originalVal = ((this.data[key])).toFixed(0) 
            vals.push(obj)
        });

        return vals;
    }
  }
}
</script>

<style scoped>
.row {
  display: flex;
  align-items: normal;
}

.row .label {
  display: block
}

.row .bar-container {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

.row .bar {
  display: flex;
  justify-content: left;
  align-items: left;
  padding: 2px
}

.row .bar-label {
  display: flex;
  justify-content: left;
  align-items: left;
  font-size: 14px;
  font-weight: 600;
}

.bar.val-0 {  color: white }
.bar.val-1 {  color: white }
.bar.val-2 { color: white }
.bar.val-3 {  color: white }

#mini-bar {
  padding: 12px;
}
</style>